<template>
  <p>
    <Button :label="t('content.watchVideo')" @click="visible = true" />
  </p>
  
  <Dialog v-model:visible="visible" maximizable :draggable="false" modal header="Video" :style="{ width: '80vw' }">
    <vue-plyr :options="{}">
      <video
          controls
          crossorigin
          playsinline
      >
        <source
            :src="props.block.url"
            type="video/mp4"
        />
      </video>
    </vue-plyr>
  </Dialog>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

import {useI18n} from "vue-i18n";
// import i18n lib
const {t, locale} = useI18n({useScope: 'global'})

const visible = ref(false);

const props = defineProps({
  block:{required: true}
})
</script>