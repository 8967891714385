export default {
    hello: '你好',
    loading: '请稍等，数据正在加载中...',
    toolbar: {
        share: '分享',
        moreInfo: '想要了解更多吗？',
        help: '帮助/教程',
    },
    share: {
        shareYourMix: '分享你的混音',
        shareYourMixDescription: '与专注粉丝互动的绝佳平台，是在DJ领域内获得更多发展的关键。',
        backToApp: '返回应用',
        submit: '提交',
        next: '继续',
        yourName: '你的名字',
        shareTitle: '%NAME% 混音的 %MIX%',
        needUrl: '只需要混音的URL吗？',
        copyUrl: '复制URL',
        urlCopied: '已复制！'
    },
    userMix: {
        info: '<p>您正在查看用户混音。<br>单击下面的按钮加载混音，然后按播放按钮听取。</p>'
            + '<p>调整滑块以创建自己的混音。准备好后，分享您的混音！</p>',
        loadMix: '我准备好了，加载混音'
    },
    yup: {
        fieldIsRequired: '此字段为必填项'
    },
    tour: {
        previous: '上一步',
        next: '下一步',
        skip: '跳过导览',
        finish: '完成导览',
    },
    controls: {
        title: '主音控制',
        resetAll: '重置全部',
        play: '播放',
        compare: '比较',
        autoUserMix: '切换到您的个人混音！'
    },
    content: {
        watchVideo: '观看视频'
    },
    footer: {
        termsOfUse: '使用条款',
        cookieStatement: 'Cookie声明',
        privacyStatement: '隐私声明',
        dmca: 'DMCA'
    },
    mobile: {
        info: '信息',
        sound: '声音'
    },
    welcome: {
        startTour: '开始导览',
        skipTour: '跳过导览'
    }
}
