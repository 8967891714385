<template>
  <component :is="currentLayout">
    <template #content-navigation>
      <div data-v-step="content-navigation" class="fadein animation-duration-500">
        <ContentNavigation></ContentNavigation>
      </div>
    </template>
    <template #content>
      <div data-v-step="content" class="fadein animation-duration-500">
        <Content v-if="store.selectedChannel"></Content>
      </div>
    </template>
    <template #controls>
      <div data-v-step="controls" class="fadein animation-duration-500">
        <Controls></Controls>
      </div>
    </template>
    <template #channels>
      <div data-v-step="channels" class="fadein animation-duration-500">
        <Channels></Channels>
      </div>
    </template>
  </component>
</template>

<script setup>
import {useAppStore} from "../stores/app"
import Controls from "./Controls"
import Content from "./Content";
import LayoutDesktop from './LayoutDesktop'
import LayoutMobile from './LayoutMobile'
import ContentNavigation from './ContentNavigation'
import Channels from "./Channels"
import {computed} from 'vue';
import {useBreakpoints} from "../util/responsive";

const store = useAppStore()
const windowWidth = useBreakpoints()

const currentLayout = computed(() => {
  return windowWidth.isMobile.value ? LayoutMobile : LayoutDesktop
})

</script>