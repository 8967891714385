export default {
    hello: 'Hello',
    loading: 'One moment please, loading data...',
    toolbar: {
        share: 'Share',
        moreInfo: 'Want to know more?',
        help: 'Help/Tutorial',
    },
    share: {
        shareYourMix: 'Share your mix',
        shareYourMixDescription: 'An amazing platform to connect with dedicated fans and key to getting more established within the DJ scene.',
        backToApp: 'Back to app',
        submit: 'Submit',
        next: 'Continue',
        yourName: 'Your name',
        shareTitle: '%MIX%, mixed by %NAME%',
        needUrl: 'Just need the URL of your mix?',
        copyUrl: 'Copy URL',
        urlCopied: 'Copied!'
    },
    userMix: {
        info: '<p>You are visiting a user mix. <br>Click the button below to load the mix, and press play to hear.</p>'
            + '<p>Just adjust the sliders to create your own mix. When ready, share your mix!</p>',
        loadMix: "Load mix"
    },
    yup: {
        fieldIsRequired: 'This field is required'
    },
    tour: {
        previous: 'Previous',
        next: 'Next',
        skip: 'Skip tour',
        finish: 'Finish tour',
    },
    controls: {
        title: 'Master Sound Controls',
        resetAll: 'Reset all',
        play: 'Play',
        compare: 'Compare',
        autoUserMix: 'Switching to your personal mix!'
    },
    content: {
        watchVideo: 'Watch video'
    },
    footer: {
        termsOfUse: 'Terms of use',
        cookieStatement: 'Cookie statement',
        privacyStatement: 'Privacy statement',
        dmca: 'DMCA'
    },
    mobile: {
        info: 'Info',
        sound: 'Sound'
    },
    welcome: {
        startTour: 'Start tour',
        skipTour: 'Skip tour'
    }
}