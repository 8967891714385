<template>
  <div class="share fadein animation-duration-500">
    <Card>
      <template #content>

        <div v-if="page === 'cookie-statement'">
          <h1 v-html="t('footer.cookieStatement')"></h1>
          <ScrollPanel class="siemensScrollBar" style="width: 100%;">
            <div v-html="appStore.legal.cookieStatement"></div>
          </ScrollPanel>
        </div>

        <div v-if="page === 'terms-of-use'">
          <h1 v-html="t('footer.termsOfUse')"></h1>
          <ScrollPanel class="siemensScrollBar" style="width: 100%;">
            <div v-html="appStore.legal.termsOfUse"></div>
          </ScrollPanel>
        </div>

        <div v-if="page === 'privacy-statement'">
          <h1 v-html="t('footer.privacyStatement')"></h1>
          <ScrollPanel class="siemensScrollBar" style="width: 100%;">
            <div v-html="appStore.legal.privacyStatement"></div>
          </ScrollPanel>
        </div>

        <div v-if="page === 'dmca'">
          <h1 v-html="t('footer.dmca')"></h1>
          <ScrollPanel class="siemensScrollBar" style="width: 100%;">
            <div v-html="appStore.legal.dmca"></div>
          </ScrollPanel>
        </div>
        
        <div class="mt-4 mb-4">
          <router-link :to="'/' + i18nStore.languageId" tag="button">
            <Button :label="t('share.backToApp')" outlined class="mr-2">
            </Button>
          </router-link>
        </div>

      </template>
    </Card>
  </div>

</template>

<script setup>
import {onMounted, ref, watch} from "vue"
import {useRoute, useRouter} from 'vue-router'
import {useAppStore} from "../stores/app"
import {useI18nStore} from "../stores/i18n"
import Card from 'primevue/card'
import Button from 'primevue/button'
import {useI18n} from 'vue-i18n'
import ScrollPanel from 'primevue/scrollpanel'

const route = useRoute()
const router = useRouter()
const appStore = useAppStore();
const i18nStore = useI18nStore();

const {t, locale} = useI18n({useScope: 'global'})

const page = ref(null)

watch(
    () => route.params,
    (params) => {
      page.value = params.page
    }
)

onMounted(() => {
  page.value = route.params.page
})
</script>