import { createApp } from "vue/dist/vue.runtime.esm-bundler";
import {createPinia} from 'pinia';
import Vue3Tour from 'vue3-tour';
import {createI18n} from 'vue-i18n'
import PrimeVue from 'primevue/config';
import App from './components/App.vue';
import VueSocialSharing from 'vue-social-sharing';
import Vue3Storage, {StorageType} from "vue3-storage";

import './main.scss';
import {messages} from "./i18n";
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import VuePlyr from 'vue-plyr'
import FloatingVue from 'floating-vue'
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import {routes} from "./routing";

const pinia = createPinia();
const app = createApp(App)

app.config.devtools = true
app.config.debug = true

app.use(PrimeVue);
app.use(Vue3Tour).provide('tours', app.config.globalProperties.$tours);
app.use(pinia);
app.use(VueSocialSharing);
app.directive('tooltip', Tooltip);
app.use(ToastService);
app.use(VuePlyr, { plyr: {} })
app.use(FloatingVue)
app.use(Vue3Storage, { namespace: "siemens_", storage: StorageType.Local })

const router = createRouter({
    history: createWebHashHistory(),
    //history: createWebHistory(),
    routes,
})

app.use(router);

const i18n = createI18n({
    locale: 'en',
    allowComposition: true,
    messages: messages
})

app.use(i18n)
app.mount("#app");

export {};