<template>
  <div class="share fadein animation-duration-500">
    <Card>
      <template #content>
        
        <div v-if="appStore.shareData">
          <h1 v-html="appStore.shareData.shareTitle"></h1>
          <p v-html="appStore.shareData.shareText"></p>
        </div>

        <div v-if="step === 0">

          <BlockUI :blocked="isLoading">
            <Form
                :initial-values="initialValues"
                :validation-schema="schema"
                @submit="onSubmit"
            >
              <Field name="name" v-slot="{ field, errorMessage }">

                <div class="flex flex-column gap-2 mt-5 mb-6">
                  <label for="username" v-html="t('share.yourName')"></label>
                  <InputText v-bind="field" :class="{ 'p-invalid': errorMessage }" />
                  <div class="p-error" v-html="errorMessage"></div>
<!--                  <small id="username-help" v-html="t('share.')"></small>-->
                </div>
                
              </Field>

              <div class="mt-4">
                <router-link :to="'/' + i18nStore.languageId" tag="button">
                  <Button :label="t('share.backToApp')" outlined class="mr-2"></Button>
                </router-link>
                <Button :loading="isLoading" :label="t('share.next')" class="" type="submit"/>
              </div>
            </Form>
          </BlockUI>

        </div>

        <div class="xshareButtons" v-if="step === 1">
          <div class="shareButtons">
            <ShareNetwork
                v-if="url"
                v-for="network in networks"
                :network="network"
                :url="url"
                :title="title"
            >
              <Button outlined class="mr-2" :label="network"></Button>
            </ShareNetwork>
            <Divider />
            <div>
              
              <p class="text-sm" v-html="t('share.needUrl')"></p>

              <div class="flex gap-2">
                <div class="flex-grow-1 flex">
                  <input ref="copyUrlInput" readonly :value="url" class="p-component p-inputtext-sm p-inputtext p-filled" style="width: 100%" />
                </div>
                <div class="flex-none flex">
                  <Button size="small" v-if="!copied" outlined class="mr-2" :label="t('share.copyUrl')" @click="copyUrl"></Button>
                  <Button size="small" v-if="copied" icon="pi pi-check" outlined class="mr-2" :label="t('share.urlCopied')" @click="copyUrl"></Button>
                </div>
              </div>

            </div>
          </div>
          <Divider />
          <div class="mt-4">
            <router-link :to="'/' + i18nStore.languageId" tag="button">
              <Button :label="t('share.backToApp')" outlined class="mr-2">
              </Button>
            </router-link>
          </div>
        </div>
        
      </template>
    </Card>
  </div>

</template>

<script setup>

// @see https://stackblitz.com/edit/vee-validate-v4-prime-vue?file=src%2FApp.vue

import Button from 'primevue/button'
import Divider from 'primevue/divider'
import Card from 'primevue/card'
import InputText from 'primevue/inputtext'
import {useI18nStore} from "../stores/i18n"
import {useRoute, useRouter} from "vue-router"
import {computed, ref} from "vue"
import {useI18n} from 'vue-i18n'
import {Field, Form} from 'vee-validate';
import * as yup from 'yup';
import {useAppStore} from "../stores/app";
import BlockUI from 'primevue/blockui';

const copyUrlInput = ref(null)
const copied = ref(false)

const {t, locale} = useI18n({useScope: 'global'})
const i18nStore = useI18nStore();

const appStore = useAppStore();

const networks = ref([
  'Facebook',
  'Twitter',
  'LinkedIn',
  'Email',
  'WhatsApp',
  'Weibo',
  'Line',
  'Xing',
  'Yammer',
]);

const route = useRoute()
const router = useRouter()

const isLoading = ref(false)
const step = ref(0)
const name = ref(null)
const url = ref(null)
const title = ref()

const initialValues = {
  name: ''
};

const schema = yup.object({
  name: yup.string()
      .trim()
      .required(() => t('yup.fieldIsRequired'))
      //.min(5, () => t('yup.fieldIsRequired')),
});

function onSubmit(values, actions) {
  //actions.resetForm();
  name.value = values.name;
  
  isLoading.value = true;
  
  title.value = t('share.shareTitle')
      .replace('%NAME%', name.value)
      .replace('%MIX%', appStore.appTitle)
  
  appStore.saveUserMix(name.value, title.value)
      .then( result => {
        url.value = result;
        step.value = 1;
        isLoading.value = false;
      })
}

function copyUrl()
{
  copyUrlInput.value.focus();
  copyUrlInput.value.select();
  copyUrlInput.value.setSelectionRange(0, 99999);
  document.execCommand('copy')
  
  copied.value = true;
  setTimeout(() => {
    copied.value = false;
  }, 3000);
}

</script>