import {Channel, Group, Mix, Sound} from './index';

export default class Builder
{
    static rebuild(mix, data)
    {
        for (let _channel of data.channels) {
            mix.setChannelVolume(_channel.id, _channel.volume)

            if (_channel.selectedA) {
                mix.setSelectedSound(_channel.id, 'A')
            }
            
            if (_channel.selectedB) {
                mix.setSelectedSound(_channel.id, 'B')
            }

            if (_channel.selectedC) {
                mix.setSelectedSound(_channel.id, 'C')
            }

            if (_channel.selectedD) {
                mix.setSelectedSound(_channel.id, 'D')
            }
        }
    }
    
    static build(data)
    {        
        const mix = new Mix;
        
        for (let _channel of data.channels) {
            
            let channel = new Channel(_channel.id)
            
            channel.soundA = _channel.soundA
            channel.soundB = _channel.soundB
            
            channel.selectedA = _channel.selectedA
            channel.selectedB = _channel.selectedB
            channel.selectedC = _channel.selectedC
            channel.selectedD = _channel.selectedD
            
            channel.soundA = new Sound(_channel.id, _channel.soundA)
            channel.soundB = new Sound(_channel.id, _channel.soundB)
            
            channel.soundAc = new Sound(_channel.id, _channel.soundAc)
            channel.soundAd = new Sound(_channel.id, _channel.soundAd)
            channel.soundBc = new Sound(_channel.id, _channel.soundBc)
            channel.soundBd = new Sound(_channel.id, _channel.soundBd)
            
            mix.addChannel(channel);
            
            // for (let _group of _channel.groups) {
            //     let group = new Group(_group.id)
            //     channel.addGroup(group)
            //    
            //     for (let _sound of _group.sounds) {
            //         let sound = new Sound(_sound.id,_sound.file)
            //         group.addSound(sound)
            //     }
            // }   
        }
        
        this.rebuild(mix, data)
        
        return mix;
    }
}