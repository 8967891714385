<template>
  <NavBar></NavBar>
  <MainTour v-if="appStore.tour"></MainTour>
  <router-view v-if="appStore.defaultMix"></router-view>
  <Footer></Footer>
  <Toast position="bottom-right" group="br"/>
  <Dialog v-model:visible="appStore.loading" modal :draggable="false" :closable="false">
    <p v-html="t('loading')" class="text-center"></p>
  </Dialog>
</template>

<script setup>
import {onMounted, watch, computed} from 'vue'
import {useAppStore} from "../stores/app"
import NavBar from "./NavBar";
import MainTour from "./tours/MainTour";
import Toast from 'primevue/toast';
import {useToast} from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import {useI18n} from "vue-i18n";
import Footer from "./Footer"
import {useBreakpoints} from "../util/responsive";


const {t, locale} = useI18n({useScope: 'global'})
const toast = useToast();
const appStore = useAppStore()
const useBreakPoint = useBreakpoints()
import { isMobile,isTablet } from 'mobile-device-detect';

onMounted(() => {
  
  // orientation problems
  if(isTablet) {
    //screen.lockOrientation("landscape");
    //screen.orientation.lock('landscape');
  } else if (isMobile) {
    //screen.lockOrientation("portrait");
    //screen.orientation.lock('portrait');
  }
  
  appStore.setCsrfToken(document.querySelector('meta[name="csrf-token"]').content);
  appStore.setSiteBaseUrl(document.querySelector('meta[name="site-base-url"]').content);
  appStore.setMixUid(document.querySelector('meta[name="mix-uid"]').content);
  
  appStore.fetchData();
});

</script>