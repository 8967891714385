import { computed, onMounted, onUnmounted, ref } from "vue"

export const useBreakpoints = () => {
    let windowWidth = ref(window.innerWidth)

    const onWidthChange = () => windowWidth.value = window.innerWidth
    onMounted(() => {
        window.addEventListener('resize', onWidthChange)
        screen.orientation.addEventListener('change', onWidthChange)
    })
    onUnmounted(() => {
        window.removeEventListener('resize', onWidthChange)
        screen.orientation.removeEventListener('change', onWidthChange)
    })

    const width = computed(() => windowWidth.value)
    
    const isMobile = computed(() => {
        return windowWidth.value <= 1024
    })

    return { width, isMobile }
}