<template>
  <div class="flex flex-row justify-content-between">
    <Channel v-if="store.defaultMix" v-for="channel in store.mix.channels" :channel="channel"/>
  </div>
</template>

<script setup>
import Channel from "./Channel"
import {useAppStore} from "../stores/app"
const store = useAppStore()
</script>