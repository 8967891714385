<template>
  <header class="site-header">
    
    <router-link :to="'/' + i18nStore.languageId" tag="button">
      <SiemensLogo></SiemensLogo>
    </router-link>
    
    <!-- mobile navbar -->
    <div class="site-header--links" v-if="true || useBreakPoint.isMobile.value">
      
      <router-link :to="'/' + i18nStore.languageId + '/welcome'" tag="button">
        <Button icon="pi pi-question" link class="" />
      </router-link>

      <div class="inline-block" data-v-step="share">
        <router-link :to="'/' + i18nStore.languageId + '/share'" tag="button">
        <span data-v-step="share">
          <Button icon="pi pi-share-alt" link class="" />
        </span>
        </router-link>
      </div>
      
      <Button icon="pi pi-language" link class="" @click="toggleLanguageOverlay" />
      <OverlayPanel ref="languageOverlay">
        <p class="p-component">
          <span v-for="language in i18nStore.languages">
            <a
                v-html="language.name"
                class="p-button p-button-link"
                :class="{
                  'text-white':language.id === selectedLanguage.id
                }"
                @click="selectedLanguage = language"
            ></a><br>
          </span>
        </p>
      </OverlayPanel>
      <Button icon="pi pi-bars" @click="showSidebar = true" link class="" />
    </div>
    
    <!-- desktop navbar -->
    <div class="site-header--links" v-if="false && !useBreakPoint.isMobile.value">
      
      <router-link :to="'/' + i18nStore.languageId + '/welcome'" tag="button">
        <Button :label="t('toolbar.help')" link class=""></Button>
      </router-link>

      <div class="inline-block" data-v-step="share">
        <router-link :to="'/' + i18nStore.languageId + '/share'" tag="button">
        <span data-v-step="share">
          <Button :label="t('toolbar.share')" link class=""></Button>
        </span>
        </router-link>
      </div>
      
      <Button :label="t('toolbar.moreInfo')" @click="showMore" link class=""></Button>
      
      <Dropdown
          class=""
          v-model="selectedLanguage"
          :options="i18nStore.languages"
          optionLabel="name"
          placeholder=""
          scrollHeight="300px"
      />
      
    </div>
  </header>
  
  <!-- the mobile sidebar -->
  <Sidebar v-model:visible="showSidebar">

    <router-link @click="showSidebar = false" :to="'/' + i18nStore.languageId + '/welcome'" tag="button">
        <span data-v-step="share">
          <Button :label="t('toolbar.help')" link class=""></Button>
        </span>
    </router-link><br>
    
    <router-link @click="showSidebar = false" :to="'/' + i18nStore.languageId + '/share'" tag="button">
        <span data-v-step="share">
          <Button :label="t('toolbar.share')" link class=""></Button>
        </span>
    </router-link><br>
    
    <Button :label="t('toolbar.moreInfo')" @click="showMore" link class=""></Button>
    
    <Divider></Divider>
    
    <p class="p-component">
      <router-link
          @click="showSidebar = false"
          :to="'/' + i18nStore.languageId + '/legal/cookie-statement'"
      >
        <Button :label="t('footer.cookieStatement')" link class=""></Button>
      </router-link><br>
    
      <router-link
          @click="showSidebar = false"
          :to="'/' + i18nStore.languageId + '/legal/terms-of-use'"
      >
        <Button :label="t('footer.termsOfUse')" link class=""></Button>
      </router-link><br>
      
      <router-link
          @click="showSidebar = false"
          :to="'/' + i18nStore.languageId + '/legal/privacy-statement'"
        >
        <Button :label="t('footer.privacyStatement')" link class=""></Button>
      </router-link><br>
      
      <router-link
          @click="showSidebar = false"
          :to="'/' + i18nStore.languageId + '/legal/dmca'"
          >
        <Button :label="t('footer.dmca')" link class=""></Button>
      </router-link>
    </p>

    <Divider></Divider>

    <p class="p-component">
      &copy; Siemens <span v-html="currentYear"></span>
    </p>
    
  </Sidebar>
</template>

<script setup>
import Button from "primevue/button"
import Dropdown from 'primevue/dropdown';
import Divider from 'primevue/divider';
import OverlayPanel from 'primevue/overlaypanel';
import SiemensLogo from "./svg/SiemensLogo"
import {computed, inject, ref ,onMounted} from "vue";
import {useI18n} from 'vue-i18n'
import {useI18nStore} from "../stores/i18n"
import {useRouter} from "vue-router";
import {useAppStore} from "../stores/app";
import Sidebar from 'primevue/sidebar';
import {useBreakpoints} from "../util/responsive";

const showSidebar = ref(false);
const useBreakPoint = useBreakpoints()

// import i18n lib
const {t, locale} = useI18n({useScope: 'global'})

// import i18n store
const i18nStore = useI18nStore();
const store = useAppStore()

const router = useRouter()

const languageOverlay = ref()
const toggleLanguageOverlay = (event) => {
  languageOverlay.value.toggle(event);
}

const currentYear = computed(() => {
  return new Date().getFullYear()
})

const showMore = function() {
  window.location = 'https://www.plm.automation.siemens.com/global/en/industries/automotive-transportation/nvh-acoustics.html'
}

const selectedLanguage = computed({
  get: () => {
    return i18nStore.language;
  },
  set: (value) => {
    i18nStore.setLanguageId(value.id);
  },
});

</script>