<template>
  <div class="fader flex flex-column gap-1 align-items-center justify-content-center">
    <div class="slider--label">
      <p class="text-sm" v-html="title"></p>
    </div>
    <div class="slider">
      <Slider v-model="volume" orientation="vertical" class=""/>
    </div>
    <div class="slider--value">
      <p class="text-sm">
        <span v-html="props.channel.volume"></span>%
      </p>
    </div>
    <div class="faderSelect">
      <ChannelSoundsAB :channel="channel"></ChannelSoundsAB>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import Slider from 'primevue/slider';
import {useAppStore} from "../stores/app";
import ChannelSoundsAB from "./ChannelSoundsAB";

const store = useAppStore();

const _value = ref('off');
const _options = ref(['Off', 'On']);

const props = defineProps({
  channel: {required: true}
})
const title = computed(() => props.channel.title)

const volume = computed({
  get: () => props.channel.volume,
  set: (value) => {
    store.setChannelVolume(props.channel.id, value);
  },
});

</script>

<style scoped>

</style>