export default [
    {
        id: 'en',
        name: 'English'
    },
    {
        id: 'ja',
        name: '日本'
    },
    {
        id: 'zh',
        name: '中国人'
    },
    {
        id: 'ko',
        name: '한국인'
    },
    {
        id: 'fr',
        name: 'français'
    },
    {
        id: 'de',
        name: 'Deutsch'
    },
]