<template>
  <svg version="1.1" id="reset" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px"
       viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
              <path d="M21.6,5C19.4,2,16,0.4,12.3,0.4c-2.6,0-5,0.8-7.1,2.4C4.5,3.4,3.8,4,3.4,4.6L2.3,6.1l-1-4.8L0,1.6l1.2,7.2l7.2-1.2L8.1,6.2
                L3.1,7l1.3-1.6c0.5-0.6,1.1-1.1,1.6-1.5c1.8-1.4,3.9-2.1,6.2-2.1c3.2,0,6.2,1.5,8.2,4.1c1.7,2.3,2.4,5,2.1,7.6
                c-0.3,2.7-1.7,5.1-3.9,6.8c-1.8,1.4-3.9,2.1-6.2,2.1c-0.4,0-0.9,0-1.4-0.1c-2.7-0.4-5-1.7-6.8-3.9c-0.9-1.2-1.6-2.6-1.9-4.1L2.3,14
                l-1.4,0.3l0.1,0.3C1.3,16.1,2,17.6,3,19c1.9,2.5,4.7,4.1,7.7,4.5c0.5,0.1,0.9,0.1,1.5,0.1c2.6,0,5-0.8,7.1-2.4
                c2.5-1.9,4.1-4.6,4.5-7.7C24.3,10.4,23.5,7.3,21.6,5z"/>
            </svg>
</template>