import {Howl} from 'howler';
import {clamp} from "./util";

export default class Sound {
    id = ''
    file = ''

    currentVolume = 0
    volume = 100
    selected = true
    selectedMix = true
    isPlaying = false

    volumeAdjustSpeed = 1

    lastRender = 0
    
    shouldStop = false

    constructor(id, file) {
        this.id = id
        this.file = file

        this.howl = new Howl({
            src: [this.file],
            loop: true,
            html5: false,
            volume: 0
        });

        //window.requestAnimationFrame(() => this.#loop())

        setInterval(() => this.#loop(), 1000/90);
    }

    #loop(timestamp) {
        let progress = timestamp - this.lastRender
        this.#update(progress)
        this.lastRender = timestamp
        //window.requestAnimationFrame(() => this.#loop())
    }

    #calculateTargetVolume() {
        let value = this.volume
        if (!this.selected) value = 0
        if (!this.selectedMix) value = 0
        if (!this.isPlaying) value = 0
        return value
    }

    #update(progress) {

        let targetVolume = this.#calculateTargetVolume()
        let adjustment = 0;
        if (this.currentVolume !== targetVolume) {
            adjustment = (targetVolume < this.currentVolume ? -1 : +1) * this.volumeAdjustSpeed
        }
        let newVolume = clamp(this.currentVolume + adjustment, 0, 100)
        
        // if(this.selectedMix && this.isPlaying && this.selected)
        // {
        //     console.log(targetVolume,this.currentVolume,newVolume);
        // }

        this.currentVolume = newVolume;
        this.howl.volume(newVolume / 100);
        
        if (newVolume === 0 && this.shouldStop) {
            this.isPlaying = false;
            this.shouldStop = false;
            this.howl.stop();
        }
    }

    setSelected(selected) {
        this.selected = selected
    }

    setVolume(volume) {
        this.volume = volume
    }

    setSelectedMix(value)
    {
        this.selectedMix = value
    }

    stop() {
        if (this.isPlaying) {
            this.isPlaying = false;
            this.shouldStop = true;
        }
    }

    play() {
        if (!this.isPlaying) {
            this.isPlaying = true;
            this.shouldStop = false;
            this.currentVolume = 0;
            this.howl.play();
        }
    }
}