<template>
</template>

<script setup>
import {onMounted} from "vue"
import {useStorage} from 'vue3-storage'
import {useRouter} from "vue-router";
import {useI18nStore} from "../stores/i18n";

const i18nStore = useI18nStore()
const router = useRouter()
const storage = useStorage()
const storageKey = 'skip_welcome'

onMounted(() => {
  const alreadyVisited = storage.hasKey(storageKey)
  if (alreadyVisited) {
    router.push({path: '/' + i18nStore.languageId + '/soundboard'})
  } else {
    router.push({path: '/' + i18nStore.languageId + '/welcome'})
  }
})

</script>