export default class Channel
{
    id = '';
    volume = 100;
    selectedMix = true
    selectedSound = null;
    
    selectedA = false;
    selectedB = false;
    selectedC = false;
    selectedD = false;
    
    soundA = null;
    soundB = null;
    
    soundAc = null;
    soundAd = null;
    soundBc = null;
    soundBd = null;
    
    constructor(id) {
        this.id = id
    }
    
    #allSounds()
    {
        return  [
            this.soundA, this.soundB,
            this.soundAc, this.soundAd,
            this.soundBc, this.soundBd
        ]
    }

    setSelectedSound(value)
    {
        this.selectedSound = value
                
        if (value === 'A') {
            this.selectedA = true;
            this.selectedB = false;
        }

        if (value === 'B') {
            this.selectedA = false;
            this.selectedB = true;
        }

        if (value === 'C') {
            this.selectedC = true;
            this.selectedD = false;
        }

        if (value === 'D') {
            this.selectedC = false;
            this.selectedD = true;
        }
        
        // update sounds
        
        this.soundA?.setSelected?.(this.selectedA)
        this.soundB?.setSelected?.(this.selectedB)
        this.soundAc?.setSelected?.(this.selectedA && this.selectedC)
        this.soundAd?.setSelected?.(this.selectedA && this.selectedD)
        this.soundBc?.setSelected?.(this.selectedB && this.selectedC)
        this.soundBd?.setSelected?.(this.selectedB && this.selectedD)
    }

    setVolume(volume) {
        this.volume = volume
        for (let _sound of this.#allSounds()) {
            _sound?.setVolume(volume)
        }
    }

    setSelectedMix(value)
    {
        this.selectedMix = value
        for (let _sound of this.#allSounds()) {
            _sound?.setSelectedMix(value)
        }
    }
    
    play() {
        for (let _sound of this.#allSounds()) {
            _sound?.play()
        }
    }
    
    stop() {
        for (let _sound of this.#allSounds()) {
            _sound?.stop()
        }
    }
}