export default {
    hello: 'Hallo',
    loading: 'Bitte warten, Daten werden geladen...',
    toolbar: {
        share: 'Teilen',
        moreInfo: 'Mehr erfahren?',
        help: 'Hilfe/Tutorial',
    },
    share: {
        shareYourMix: 'Teile deinen Mix',
        shareYourMixDescription: 'Eine unglaubliche Plattform, um sich mit engagierten Fans zu verbinden und ein wichtiger Schritt, um in der DJ-Szene bekannter zu werden.',
        backToApp: 'Zurück zur App',
        submit: 'Absenden',
        next: 'Weiter',
        yourName: 'Dein Name',
        shareTitle: '%NAME% - Mix %MIX%',
        needUrl: 'Brauchst du nur die URL deines Mixes?',
        copyUrl: 'URL kopieren',
        urlCopied: 'Kopiert!'
    },
    userMix: {
        info: '<p>Du besuchst einen Benutzermix. <br>Klicke auf die Schaltfläche unten, um den Mix zu laden, und drücke auf Wiedergabe, um ihn anzuhören.</p>'
            + '<p>Stelle einfach die Schieberegler ein, um deinen eigenen Mix zu erstellen. Wenn du fertig bist, teile deinen Mix!</p>',
        loadMix: 'Ich bin bereit, den Mix zu laden'
    },
    yup: {
        fieldIsRequired: 'Dieses Feld ist erforderlich'
    },
    tour: {
        previous: 'Zurück',
        next: 'Weiter',
        skip: 'Tour überspringen',
        finish: 'Tour beenden',
    },
    controls: {
        title: 'Master-Sound-Steuerung',
        resetAll: 'Alles zurücksetzen',
        play: 'Abspielen',
        compare: 'Vergleichen',
        autoUserMix: 'Wechsel zu deinem persönlichen Mix!'
    },
    content: {
        watchVideo: 'Video ansehen'
    },
    footer: {
        termsOfUse: 'Nutzungsbedingungen',
        cookieStatement: 'Cookie-Erklärung',
        privacyStatement: 'Datenschutzerklärung',
        dmca: 'DMCA'
    },
    mobile: {
        info: 'Info',
        sound: 'Ton'
    },
    welcome: {
        startTour: 'Tour starten',
        skipTour: 'Tour überspringen'
    }
}
