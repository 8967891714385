<template>
  <v-tour
      name="mainTour"
      :steps="tourSteps"
      :options="tourOptions"
      :callbacks="tourCallbacks"
  ></v-tour>
</template>

<script setup>
import {computed} from 'vue';
import {useI18n} from 'vue-i18n'
import {useAppStore} from "../../stores/app";
import {useBreakpoints} from "../../util/responsive";
import {useI18nStore} from "../../stores/i18n";
import {useRouter} from "vue-router";
const {t, locale} = useI18n({useScope: 'global'})
const i18nStore = useI18nStore()
const router = useRouter()

const appStore = useAppStore()
const breakPoint = useBreakpoints()

const tourOptions = computed(() => {
  return {
    highlight: true,
    labels: {
      buttonSkip: t('tour.skip'),
      buttonPrevious: t('tour.previous'),
      buttonNext: t('tour.next'),
      buttonStop: t('tour.finish'),
    }
  }
})

const tourCallbacks = computed(() => {
  return {
    onStart: () => {
      appStore.mobileShowInfo = true;
    },
    onPreviousStep: (currentStep) => {
      layoutChangesOnStep(currentStep-1)
    },
    onNextStep: (currentStep) => {
      layoutChangesOnStep(currentStep+1)
    },
    onSkip: () => {},
    onFinish: () => {},
    onStop: () => {},
  }
})

function layoutChangesOnStep(step)
{
  console.log('layoutChangesOnStep',step)
  if (step === 0) {
    appStore.mobileShowInfo = true;
  }
  if (step === 1) {
    appStore.mobileShowInfo = false;
  }
}

const tourSteps = computed(() => {
  return [
    {
      target: '[data-v-step="content-navigation"]',
      header: {
        title: appStore.tour.infoPanelTitle,
      },
      content: appStore.tour.infoPanelText,
      params: {
        placement: breakPoint.isMobile.value ? 'bottom':'right'
      }
    },
    {
      target: '[data-v-step="controls"]',
      header: {
        title: appStore.tour.controlsTitle,
      },
      content: appStore.tour.controlsText,
      params: {
        placement: breakPoint.isMobile.value ? 'bottom':'bottom'
      }
    },
    {
      target: '[data-v-step="channels"]',
      header: {
        title: appStore.tour.channelsTitle,
      },
      content: appStore.tour.channelsText,
      params: {
        placement: breakPoint.isMobile.value ? 'bottom':'left'
      }
    },
    {
      target: '[data-v-step="share"]',
      header: {
        title: appStore.tour.shareTitle,
      },
      content: appStore.tour.shareText,
      params: {
        placement: breakPoint.isMobile.value ? 'bottom':'left'
      }
    },
  ]
})

/*
type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';
 */
</script>