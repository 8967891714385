<template>

  <div class="soundcrontrols--wrapper">
    <p class="soundcontrols--label"><span v-html="t('controls.title')"></span></p>

    <Card class="soundcontrols">
      <template #content>
        <!--      <p>-->
        <!--        mix: <span v-html="store.isUserMix ? 'Y':'N'"></span>-->
        <!--        mixModel: <span v-html="mixModel"></span>-->
        <!--      </p>-->
        <div class="flex flex-row justify-content-between w-full card-container">
          <div class="flex flex-shrink-0 align-items-center justify-content-center mr-3">
            <SpeakerIcon></SpeakerIcon>
          </div>
          <div class="flex flex-column flex-grow-1 align-items-center justify-content-center mr-5">
            <Slider v-model="masterVolume" class="w-full"/>
          </div>
          <div class="flex flex-column flex-shrink-0 align-items-center justify-content-center mr-3">
            <ToggleButton
                onIcon="pi pi-pause" onLabel=""
                offIcon="pi pi-play" offLabel=""
                class="xw-9rem"
                v-model="isPlaying"
            />
            <span class="soundcontrol--label" v-html="t('controls.play')"></span>
          </div>
          <div class="flex flex-column flex-shrink-0 align-items-center justify-content-center mr-3">

            <div>
              <Popper content="This is the Popper content 🍿" :arrow="true" placement="top" :show="store.isUserMixAuto">
                <template #content>
                  <div v-html="t('controls.autoUserMix')"></div>
                </template>
                <SelectButton
                    id="compareButton"
                    ref="compareButton"
                    :unselectable="false"
                    :options="mixOptions"
                    v-model="mixModel"
                    optionLabel="value"
                    optionValue="value"
                >
                  <template #option="slotProps">
                    <i :class="slotProps.option.icon"></i>
                  </template>
                </SelectButton>
              </Popper>
            </div>

            <!--          v-tooltip="'You have new messages.'"-->

            <div v-if="store.mix">
              <span class="soundcontrol--label" v-html="t('controls.compare')"></span>
            </div>
          </div>
          <div class="flex flex-column flex-shrink-0 align-items-center justify-content-center mr-3">
            <Button @click="resetUserMix" class="btn-soundcontrol btn-soundcontrol--reset">
              <ResetIcon></ResetIcon>
            </Button>
            <span class="soundcontrol--label" v-html="t('controls.resetAll')"></span>
          </div>
        </div>

      </template>
    </Card>
<!--    <div style="position:relative">-->
<!--      <div style="position: absolute; bottom:0">-->
<!--        <FrequencyBarGraph></FrequencyBarGraph>-->
<!--      </div> -->
<!--    </div>-->
  </div>
  
</template>

<script setup>
import {computed, ref} from "vue"
import {useAppStore} from "../stores/app"
import {useI18n} from 'vue-i18n'
import Button from 'primevue/button'
import Slider from 'primevue/slider'
import Card from 'primevue/card'
import ToggleButton from 'primevue/togglebutton'
import SelectButton from "primevue/selectbutton"
import ResetIcon from './svg/ResetIcon'
import SpeakerIcon from './svg/SpeakerIcon'
import Popper from "vue3-popper";
import FrequencyBarGraph from './audio/FrequencyBarGraph'

// import i18n lib
const {t, locale} = useI18n({useScope: 'global'})

const checked = ref(false);

const store = useAppStore();

const isPlaying = computed({
  get: () => store.isPlaying,
  set: (value) => store.play(value),
});

const masterVolume = computed({
  get: () => store.masterVolume,
  set: (value) => store.setMasterVolume(value),
});

const mixOptions = ref([
  {icon: 'pi pi-refresh', value: 'defaultMix'},
  {icon: 'pi pi-star', value: 'userMix'},
]);

const resetUserMix = () => {
  store.resetUserMix();
}

const mixModel = computed({
  get: () => {
    return store.isUserMix ? 'userMix' : 'defaultMix';
  },
  set: (v) => {
    if (v === 'userMix') {
      store.setUserMix();
    } else if (v === 'defaultMix') {
      store.setDefaultMix();
    }
  }
})

</script>

<style scoped>
</style>