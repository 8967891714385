<template>
  <router-view></router-view>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {useI18n} from 'vue-i18n'

const route = useRoute()

const {t, locale} = useI18n({useScope: 'global'})

watch(
    () => route.params,
    (params) => {
      locale.value = params.locale;
    }
)
</script>

<style scoped>

</style>