export default class Mix {
    
    selectedMix = true
    
    constructor() {
        this.channels = [];
    }

    setSelectedMix(value)
    {
        this.selectedMix = value
        for (let _channel of this.channels) {
            _channel.setSelectedMix(value)
        }
    }
    
    addChannel(channel) {
        this.channels.push(channel)
    }
    
    setChannelVolume(id, volume) {
        let channel = this.channels.find(v => v.id === id);
        channel.setVolume(volume);
    }
    
    setSelectedSound(channelId, selection)
    {
        let channel = this.channels.find(v => v.id === channelId);
        channel.setSelectedSound(selection)
    }
    
    play() {
        for (let _channel of this.channels) {
            _channel.play()
        }
    }

    stop() {
        for (let _channel of this.channels) {
            _channel.stop()
        }
    }
}