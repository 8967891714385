<template>
  <div class="share fadein animation-duration-500">
    <Card>
      <template #content>
        
        <div v-if="appStore.sharedMix">
          
          <div v-if="appStore.shareData">
            <h1 v-html="userMixTitle"></h1>
            <p v-html="userMixText"></p>
          </div>
          
          <div class="mt-4">
            <Button 
                :label="t('userMix.loadMix')" 
                @click="loadApp"
                outlined class="mr-2"
            >
            </Button>
          </div>
        </div>

      </template>
    </Card>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue"
import {useRoute, useRouter} from 'vue-router'
import {useAppStore} from "../stores/app"
import {useI18nStore} from "../stores/i18n"
import Card from 'primevue/card'
import Button from 'primevue/button'
import {useI18n} from 'vue-i18n'

const route = useRoute()
const router = useRouter()
const appStore = useAppStore();
const i18nStore = useI18nStore();

const {t, locale} = useI18n({useScope: 'global'})

const isLoading = ref(false)

const userMixTitle = computed(() => {
  return appStore.shareData?.userMixTitle?.replace('%NAME%', appStore.sharedMix?.name);
})

const userMixText = computed(() => {
  return appStore.shareData?.userMixText?.replace('%NAME%', appStore.sharedMix?.name);
})

watch(
    () => route.params,
    (params) => {
      loadUserMix(params.uid)
    }
)

onMounted(() => {
  loadUserMix(route.params.uid)
})

function loadUserMix(uid) {
  if (uid) {
    isLoading.value = true;
    appStore.loadUserMix(uid)
        .then(result => {
          isLoading.value = false;
          appStore.setSharedMix(result)
        })
  }
}

function loadApp()
{
  const url = '/' + i18nStore.languageId + '/soundboard'
  router.push({path:url})
}
</script>