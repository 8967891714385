export default {
    hello: 'Bonjour',
    loading: 'Un instant s\'il vous plaît, chargement des données en cours...',
    toolbar: {
        share: 'Partager',
        moreInfo: 'En savoir plus ?',
        help: 'Aide/Tutoriel',
    },
    share: {
        shareYourMix: 'Partagez votre mix',
        shareYourMixDescription: 'Une plateforme incroyable pour se connecter avec des fans dévoués et essentielle pour s\'établir davantage dans la scène des DJ.',
        backToApp: 'Retour à l\'application',
        submit: 'Soumettre',
        next: 'Suivant',
        yourName: 'Votre nom',
        shareTitle: '%NAME% - Mix %MIX%',
        needUrl: 'Besoin seulement de l\'URL de votre mix ?',
        copyUrl: 'Copier l\'URL',
        urlCopied: 'Copié !'
    },
    userMix: {
        info: '<p>Vous consultez un mix utilisateur. <br>Cliquez sur le bouton ci-dessous pour charger le mix, puis appuyez sur lecture pour écouter.</p>'
            + '<p>Ajustez simplement les curseurs pour créer votre propre mix. Quand vous êtes prêt, partagez votre mix !</p>',
        loadMix: 'Je suis prêt, charger le mix'
    },
    yup: {
        fieldIsRequired: 'Ce champ est obligatoire'
    },
    tour: {
        previous: 'Précédent',
        next: 'Suivant',
        skip: 'Passer la visite',
        finish: 'Terminer la visite',
    },
    controls: {
        title: 'Contrôles audio maître',
        resetAll: 'Tout réinitialiser',
        play: 'Lecture',
        compare: 'Comparer',
        autoUserMix: 'Passage à votre mix personnel !'
    },
    content: {
        watchVideo: 'Regarder la vidéo'
    },
    footer: {
        termsOfUse: 'Conditions d\'utilisation',
        cookieStatement: 'Déclaration de cookies',
        privacyStatement: 'Déclaration de confidentialité',
        dmca: 'DMCA'
    },
    mobile: {
        startTour: 'Commencer la visite',
        skipTour: 'Sauter la visite'
    }
}
