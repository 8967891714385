import {defineStore} from 'pinia';
import {computed, onMounted, ref, watch} from "vue";

import {languages as _languages} from "../i18n";
import {useRoute, useRouter} from "vue-router";

import {useI18n} from 'vue-i18n'

import {useAppStore} from "./app";

export const useI18nStore = defineStore('i18nStore', () => {

    const languages = ref(_languages)
    const languageId = ref(_languages[0].id);

    const route = useRoute()
    const router = useRouter()
    
    const appStore = useAppStore()

    const language = computed(() => {
        return languages.value.find(v => v.id === languageId.value)
    });

    const {locale} = useI18n({useScope: 'global'})

    // watch locale change, to push in URL
    watch(
        languageId,
        (newValue, oldValue) => {
            let path = router.currentRoute.value.path;
            path = path.replace('/' + oldValue, '/' + newValue);
            router.push(path);
            locale.value = newValue;
            
            // stop playing
            appStore.refetchData()
        }
    );

    onMounted(async () => {
        await router.isReady();
        const languageId = router.currentRoute.value.params.locale;
        setLanguageId(languageId);
    })

    function setLanguageId(value) {
        languageId.value = value;
    }

    function setLanguage(value) {
        setLanguageId(value.id);
    }

    return {
        languages,
        language,
        languageId,
        setLanguageId,
        setLanguage
    };
});