<template>
  <div class="share welcome fadein animation-duration-500">
    <div class="m-5">
      <Card class="">
        <template #content>

          <div class="fancy-welcome">
            <h1 class="fancy-welcome-title" v-html="appStore.tour.welcomeTitle"></h1>
            <div class="fancy-welcome-subtitle" v-html="appStore.tour.welcomeText"></div>
            <p class="fancy-welcome-actions">
              <Button :label="t('welcome.startTour')" @click="startTour" class="mr-2"></Button>
              <Button :label="t('welcome.skipTour')" @click="gotoSoundboard" class="p-button p-component p-button-outlined"></Button>
            </p>
          </div>
          
          <div class="mt-5">
            <div class="mt-4 mb-4" v-html="appStore.tour.welcomeVideoEmbedCode"></div>
            <div v-html="appStore.tour.welcomeMoreText"></div>
          </div>

        </template>
      </Card>
    </div>
  </div>
</template>

<script setup>
import {inject, onMounted} from "vue"
import {useStorage} from 'vue3-storage'
import {useRouter} from "vue-router";
import {useI18nStore} from "../stores/i18n";
import Button from 'primevue/button'
import Card from 'primevue/card'
import {useI18n} from "vue-i18n";
import {useAppStore} from "../stores/app";

const tours = inject('tours')
const i18nStore = useI18nStore()
const router = useRouter()
const storage = useStorage()
const storageKey = 'skip_welcome'
const {t, locale} = useI18n({useScope: 'global'})

const appStore = useAppStore()

onMounted(() => {
})

function startTour()
{
  storage.setStorageSync(storageKey,true)
  router.push({path: '/' + i18nStore.languageId + '/soundboard'})
  tours['mainTour'].start()
}

function gotoSoundboard()
{
  storage.setStorageSync(storageKey,true)
  router.push({path: '/' + i18nStore.languageId + '/soundboard'})
}

</script>