<template>
  <footer class="site-footer">
    <div>
      <ul v-if="useBreakPoint.isMobile.value">
        <li>
          &copy; Siemens 2023
        </li>
        <li>
          <router-link
             :to="'/' + i18nStore.languageId + '/welcome'"
             v-html="t('toolbar.help')"
          >
          </router-link>
        </li>
      </ul>
      <ul v-if="!useBreakPoint.isMobile.value">
        <li>
          &copy; Siemens 2023
        </li>
        <li>
          <router-link
              :to="'/' + i18nStore.languageId + '/legal/cookie-statement'"
              v-html="t('footer.cookieStatement')"
          >
          </router-link>
        </li>
        <li>
          <router-link
              :to="'/' + i18nStore.languageId + '/legal/terms-of-use'"
              v-html="t('footer.termsOfUse')">
          </router-link>
        </li>
        <li>
          <router-link
              :to="'/' + i18nStore.languageId + '/legal/privacy-statement'"
              v-html="t('footer.privacyStatement')">
          </router-link>
        </li>
        <li>
          <router-link
              :to="'/' + i18nStore.languageId + '/legal/dmca'"
              v-html="t('footer.dmca')">
          </router-link>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script setup>
import {useI18nStore} from "../stores/i18n"
import {useI18n} from "vue-i18n"
import {useBreakpoints} from "../util/responsive"

const useBreakPoint = useBreakpoints()
const i18nStore = useI18nStore()
const {t, locale} = useI18n({useScope: 'global'})

</script>