<template>
  <main class="site-main">
    <div class="site-main--left">
      <div class="toolbar toolbar--left">
        <div class="nav--info">
          <slot name="content-navigation"></slot>
        </div>
      </div>
      <div class="content content--left">
        <slot name="content"></slot>
      </div>
    </div>
    <div class="site-main--right">
      <div class="toolbar toolbar--right">
        <slot name="controls"></slot>
      </div>
      <div class="content content--right">
        <slot name="channels"></slot>
      </div>
    </div>
  </main>
</template>

<script setup>
</script>