<template>
  <Button
      v-for="channel in store.contentData"
      @click="store.setSelectedChannel(channel)"
      :class="{'highlighted-button': channel === store.selectedChannel}"
  >
    <span v-html="channel.icon"></span>
    <span v-html="channel.title"></span>
  </Button>
</template>

<script setup>
import {useAppStore} from "../stores/app"
import Button from "primevue/button";
const store = useAppStore()
</script>