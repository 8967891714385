import {defineStore} from 'pinia';
import {computed, ref, watch} from "vue";
import axios from "axios"
import {Builder} from '../audio'
import {useI18nStore} from "./i18n";

export const useAppStore = defineStore('appStore', () => {
    
    const i18nStore = useI18nStore()
    
    // this works, but then sounds.js should not use requestAnimationFrame
    // requestAnimationFrame => only in active tabs
    const bc = new BroadcastChannel('siemens_acoustic_demo');

    bc.onmessage = function (ev) {
        const message = ev.data;
        if (message.type === 'play') {
            play(false)
        }
    }

    document.addEventListener("visibilitychange", (event) => {
    });

    /** DATA **/
    
    const csrfToken = ref(null)
    const siteBaseUrl = ref(null)
    const mixUid = ref(null)
    const mixUrl = ref(null)
    
    const tour = ref(null)
    const legal = ref(null)
    
    const sharedMix = ref(null)
    
    const isMobile = ref(false);
    
    const loading = ref(true);
    const baseDataLoaded = ref(false)

    const isPlaying = ref(false);
    const masterVolume = ref(75);

    const appTitle = ref('Siemens Acoustic Demo');

    const defaultMix = ref(null);
    const userMix = ref(null);
    const isUserMix = ref(false);
    const isUserMixAuto = ref(false);
    
    const contentData = ref(null);
    const audioData = ref(null);
    const shareData = ref(null);

    let defaultMixAudio = null;
    let userMixAudio = null;
    
    const mobileShowInfo = ref(false)
    
    const selectedChannel = ref(null);

    /** GETTERS **/

    const mix = computed(() => {
        return isUserMix.value ? userMix.value : defaultMix.value;
    });
    
    const audio = computed(() => {
        return isUserMix.value ? userMixAudio : defaultMixAudio;
    })

    /** WATCHERS **/

    watch(isPlaying,
        (newValue, oldValue) => {
            if (newValue) {
                defaultMixAudio.play();
                userMixAudio.play();

                bc.postMessage({type:'play'});
                // audio.value.play()
            } else {
                defaultMixAudio.stop();
                userMixAudio.stop();
                // audio.value.stop()
                bc.postMessage({type:'stop'});
            }
        }
    )

    watch(isUserMix,
        (newValue, oldValue) => {
            defaultMixAudio.setSelectedMix(!newValue)
            userMixAudio.setSelectedMix(newValue)
        }
    )

    /** ACTIONS **/
    
    function sleep(ms)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    function getAxios() {
        const instance = axios.create()
        instance.defaults.baseURL = siteBaseUrl.value
        instance.defaults.headers.common['X-CSRF-Token'] = csrfToken.value
        return instance
    }
    
    async function loadUserMix(uid)
    {        
        await sleep(500)
        const params = {
            uid: uid
        }

        return getAxios()
            .post('actions/siemens-acoustic-demo/api/load-user-mix',params)
            .then(response => response.data)
    }
    
    function setSharedMix(value) {
        sharedMix.value = value;
        mergeMixIntoDefaultAndSetAsUserMix(value.userMix);
    }
    
    async function saveUserMix(name, title)
    {
        await sleep(500)
        const params = {
            locale: i18nStore.languageId,
            mix: mixUid.value,
            userMix: JSON.parse(JSON.stringify(userMix.value)),
            name: name,
            title: title,
            url: mixUrl.value + '#/' + i18nStore.languageId + '/user-mix/##SHARE-UID###'
        }
        
        return getAxios()
            .post('actions/siemens-acoustic-demo/api/save-user-mix',params)
            .then(response => response.data)
    }
    
    // async function fetchAll()
    // {
    //     return fetchSounds()
    //         .then( () => fetchSound('/uploads/test-sounds-music/mixkit-drumming-atmospheric-570.wav'))
    //         .then( () => wait(2000) )
    //         .then( () => fetchSound('/uploads/test-sounds-music/mixkit-tribal-drumming-ambience-572.wav'))
    //         .then( () => console.log('ready') );
    // }
    //
    // async function wait(ms)
    // {
    //     console.log('waiting',ms);
    //     return new Promise((resolve) => {
    //         setTimeout(() => {
    //             resolve()
    //         }, ms);
    //     });
    // }
    //
    // async function fetchSound(file)
    // {
    //     return new Promise((resolve) => {
    //         console.log('preloading sound',file)
    //         new Howl({
    //             src: [file],
    //             preload: true,
    //             onload: () => {
    //                 console.log('preloading sound ready')
    //                 resolve()
    //             }
    //         });
    //     });
    // }
    
    async function fetchTour()
    {
        const params = {
            locale: i18nStore.languageId,
            mix:mixUid.value
        }

        return getAxios()
            .post('actions/siemens-acoustic-demo/api/tour', params)
            .then(response => response.data)
            .then(data => {
                tour.value = data
            })
            ;
    }

    async function fetchLegal()
    {
        const params = {
            locale: i18nStore.languageId,
            mix:mixUid.value
        }

        return getAxios()
            .post('actions/siemens-acoustic-demo/api/legal', params)
            .then(response => response.data)
            .then(data => {
                legal.value = data
            })
            ;
    }

    async function fetchShare()
    {
        const params = {
            locale: i18nStore.languageId,
            mix:mixUid.value
        }

        return getAxios()
            .post('actions/siemens-acoustic-demo/api/share', params)
            .then(response => response.data)
            .then(data => {
                shareData.value = data;
            })
            ;
    }
    
    async function fetchContent()
    {
        const params = {
            locale: i18nStore.languageId,
            mix:mixUid.value
        }

        return getAxios()
            .post('actions/siemens-acoustic-demo/api/content', params)
            .then(response => response.data)
            .then(data => {
                contentData.value = data;
                selectedChannel.value = contentData.value[0];
            })
            ;
    }

    async function fetchAudio()
    {
        const params = {
            locale: i18nStore.languageId,
            mix:mixUid.value
        }

        return getAxios()
            .post('actions/siemens-acoustic-demo/api/audio', params)
            .then(response => response.data)
            .then(data => {
                defaultMix.value = data;
                if (baseDataLoaded.value === false) {
                    resetUserMix();
                    createAudio();
                    baseDataLoaded.value = true;
                } else {
                    mergeMixIntoDefaultAndSetAsUserMix(userMix.value);
                }
            })
            ;
    }

    async function fetchBaseData()
    {
        const params = {
            locale: i18nStore.languageId,
            mix:mixUid.value
        }

        return getAxios()
            .post('actions/siemens-acoustic-demo/api/index', params)
            .then(response => response.data)
            .then(data => {
                appTitle.value = data.title;
                mixUrl.value = data.url;
            })
            ;
    }

    async function fetchData() {
        loading.value = true
        
        await fetchBaseData()
        await fetchTour()
        await fetchLegal()
        await fetchContent()
        await fetchShare()
        await fetchAudio()

        loading.value = false;
        baseDataLoaded.value = true;
    }

    function resetUserMix() {
        userMix.value = JSON.parse(JSON.stringify(defaultMix.value));
        userMix.value.title = 'User mix';
        updateAudio();
    }
    
    function mergeMixIntoDefaultAndSetAsUserMix(mixData)
    {
        // clone the default mix
        const tmpMix = JSON.parse(JSON.stringify(defaultMix.value));
        
        // find all channel-volumes and selected sounds, merge info tmpMix
        if (mixData) {
            tmpMix.title = mixData.title;
            for (let channelKey in tmpMix.channels) {
                tmpMix.channels[channelKey].volume = mixData.channels[channelKey].volume
                
                tmpMix.channels[channelKey].selectedA = mixData.channels[channelKey].selectedA
                tmpMix.channels[channelKey].selectedB = mixData.channels[channelKey].selectedB
                tmpMix.channels[channelKey].selectedC = mixData.channels[channelKey].selectedC
                tmpMix.channels[channelKey].selectedD = mixData.channels[channelKey].selectedD
                
            }
        }
        
        // set as userMix
        userMix.value = JSON.parse(JSON.stringify(tmpMix));
        
        if (mixData) {
            isUserMix.value = true;
        }
        updateAudio();
    }

    async function refetchData() {
        return fetchData();
    }

    function createAudio() {
        defaultMixAudio = Builder.build(defaultMix.value);
        userMixAudio = Builder.build(userMix.value);
        defaultMixAudio.setSelectedMix(true);
        userMixAudio.setSelectedMix(false);
        Howler.volume(masterVolume.value/100*.75); // 75% of real volume, to prevent overdrive
    }

    function updateAudio()
    {
        if (userMixAudio && userMix.value) {
            Builder.rebuild(userMixAudio, userMix.value)
            console.log('global volume', masterVolume.value)
            Howler.volume(masterVolume.value/100*.75); // 75% of real volume, to prevent overdrive
        }
    }

    function switchToUserMix() {
        if (isUserMix.value === false) {
            isUserMix.value = true;
            isUserMixAuto.value = true;
            setTimeout(() => {
                isUserMixAuto.value = false;
            },3000);
        }
    }
    
    function setCsrfToken(value) {
        csrfToken.value = value; 
    }

    function setMixUid(value) {
        mixUid.value = value;
    }
    
    function setSiteBaseUrl(value) {
        siteBaseUrl.value = value;
    }

    function setUserMix() {
        isUserMix.value = true;
    }

    function setDefaultMix() {
        isUserMix.value = false;
    }

    function play(playing) {
        isPlaying.value = playing
    }

    function setMasterVolume(volume) {
        masterVolume.value = volume;
        updateAudio();
    }

    function setChannelVolume(channelId, volume) {
        switchToUserMix();
        const channel = userMix.value.channels.find(v => v.id === channelId)
        channel.volume = volume;
        updateAudio();
    }

    function setSelectedSound(channelId, selection) {
        switchToUserMix();
        
        const channel = userMix.value.channels.find(v => v.id === channelId)
        
        if (selection === 'A') {
            channel.selectedA = true;
            channel.selectedB = false;
        }
        
        if (selection === 'B') {
            channel.selectedA = false;
            channel.selectedB = true;
        }

        if (selection === 'C') {
            channel.selectedC = true;
            channel.selectedD = false;
        }

        if (selection === 'D') {
            channel.selectedC = false;
            channel.selectedD = true;
        }
        
        
        // const group = channel.groups.find(v => v.id === groupId)
        // const sound = group.sounds.find(v => v.id === soundId)
        //
        // for (let _sound of group.sounds) {
        //     _sound.selected = false;
        // }
        // sound.selected = true;

        // change audio
        updateAudio();
    }
    
    function setSelectedChannel(channel)
    {
        selectedChannel.value = channel;
    }

    return {
        csrfToken,
        mixUid,
        loading,
        appTitle,
        isPlaying,
        masterVolume,
        defaultMix,
        userMix,
        isUserMix,
        isUserMixAuto,
        selectedChannel,
        isMobile,
        siteBaseUrl,
        mixUrl,
        setMixUid,
        saveUserMix,
        setSiteBaseUrl,
        setCsrfToken,
        setSelectedChannel,
        setDefaultMix,
        setUserMix,
        loadUserMix,
        play,
        fetchData,
        refetchData,
        setMasterVolume,
        setChannelVolume,
        setSelectedSound,
        resetUserMix,
        sharedMix,
        setSharedMix,
        mix,
        tour,
        legal,
        mobileShowInfo,

        audioData,
        contentData,
        shareData
    };
});