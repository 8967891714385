<template>
  <svg id="logo" data-name="Group 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="190" height="30" viewBox="0 0 190 30">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_1" data-name="Rectangle 1" width="189.847" height="30" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
      <path id="Path_1" data-name="Path 1" d="M.586,28.948v-5.71a29.222,29.222,0,0,0,8.627,1.534q5.181,0,5.181-2.737a2.234,2.234,0,0,0-.755-1.715,15.457,15.457,0,0,0-3.955-2.044q-5.707-2.347-7.44-4A7.372,7.372,0,0,1,0,8.76,7.436,7.436,0,0,1,3.268,2.225,14.54,14.54,0,0,1,11.686,0,47.03,47.03,0,0,1,19.96,1.053V6.546a20.269,20.269,0,0,0-7.481-1.608q-4.865,0-4.864,2.675A1.886,1.886,0,0,0,8.6,9.247q.816.512,4.492,2.165,5.292,2.35,7.047,4.088a7.177,7.177,0,0,1,2.084,5.331A7.9,7.9,0,0,1,18.133,28a16.4,16.4,0,0,1-8.581,2A39.34,39.34,0,0,1,.586,28.948" transform="translate(0 0)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_2" data-name="Path 2" d="M35.707.67h8.076V29.584H35.707Z" transform="translate(-8.927 -0.168)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_3" data-name="Path 3" d="M56.351,29.583V.67H77.064V5.9H64.134v6.529H75.389V17.2H64.134V24.06H77.4v5.524Z" transform="translate(-14.088 -0.168)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_4" data-name="Path 4" d="M91.442,29.583V.67h10.471l7.275,18.474L116.645.67h9.946V29.583h-7.657V9.112l-8.479,20.764h-5.006L97.132,9.112V29.583Z" transform="translate(-22.861 -0.168)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_5" data-name="Path 5" d="M148.185,29.583V.67H168.9V5.9H155.969v6.529h11.255V17.2H155.969V24.06h13.264v5.524Z" transform="translate(-37.046 -0.168)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_6" data-name="Path 6" d="M183.334,29.583V.67h9.36l9.931,19.355V.67h5.689V29.583h-9.094L189.025,9.968V29.583Z" transform="translate(-45.834 -0.168)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_7" data-name="Path 7" d="M224.09,28.948v-5.71a28.921,28.921,0,0,0,8.628,1.534q5.182,0,5.18-2.737a2.272,2.272,0,0,0-.731-1.715,15.557,15.557,0,0,0-3.977-2.044q-5.69-2.327-7.442-4a7.358,7.358,0,0,1-2.241-5.533,7.409,7.409,0,0,1,3.267-6.515A14.534,14.534,0,0,1,235.193,0a40.074,40.074,0,0,1,7.558.929l.714.124V6.546a20.318,20.318,0,0,0-7.5-1.608q-4.845,0-4.841,2.675a1.886,1.886,0,0,0,.977,1.634q.777.492,4.513,2.165,5.253,2.35,7.025,4.087a7.167,7.167,0,0,1,2.085,5.332A7.906,7.906,0,0,1,241.659,28a16.5,16.5,0,0,1-8.6,2,39.271,39.271,0,0,1-8.969-1.052" transform="translate(-55.877 0)" fill="#fff" fill-rule="evenodd"/>
    </g>
  </svg>
</template>

<script setup>
</script>