<template>
  <!--<svg version="1.1" id="soundOn" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px"
       viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
            <g>
              <path style="fill:#009999;" d="M18.4,19.3c-0.3,0-0.7-0.2-0.8-0.6c-0.1-0.4,0.1-0.9,0.5-1.1c2.5-0.8,4.2-3.2,4.2-5.8
                c0-2.6-1.7-4.9-4.1-5.8c-0.4-0.1-0.7-0.6-0.5-1.1c0.1-0.4,0.6-0.7,1.1-0.5c3.1,1.1,5.2,4,5.2,7.3c0,3.4-2.1,6.3-5.3,7.4
                C18.6,19.3,18.5,19.3,18.4,19.3L18.4,19.3z"/>
              <path style="fill:#009999;" d="M19.8,11.9c0-2-1.6-3.6-3.6-3.6V2.4c0-0.9-0.5-1.7-1.3-2.1c-0.7-0.4-1.5-0.3-2.2,0.2L4.9,6H2.2
                C1,6,0,7,0,8.2v7.4c0,1.2,1,2.2,2.2,2.2h2.7l7.8,5.6c0.4,0.3,0.8,0.4,1.2,0.4c0.3,0,0.7-0.1,1-0.2c0.8-0.4,1.3-1.2,1.3-2.1v-5.9
                C18.2,15.5,19.8,13.9,19.8,11.9L19.8,11.9z M4.4,16.2H2.2c-0.3,0-0.6-0.2-0.6-0.6V8.2c0-0.3,0.2-0.6,0.6-0.6h2.2V16.2z M14.6,21.5
                c0,0.4-0.2,0.6-0.4,0.7c-0.2,0.1-0.3,0.1-0.5,0l-7.7-5.5V7.3l7.7-5.5c0.2-0.1,0.3-0.1,0.5,0c0.2,0.1,0.4,0.3,0.4,0.7L14.6,21.5
                L14.6,21.5z M16.2,13.9V10c1.1,0,1.9,0.9,1.9,1.9C18.2,13,17.3,13.9,16.2,13.9z"/>
            </g>
          </svg>-->
  <svg :class="{animated : appStore.isPlaying}" version="1.1" id="speaker-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
       y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <path id="large" style="fill:#009999;" d="M392.7,414.3c-7.5,0-14.4-4.7-16.9-12.2c-3.1-9.3,2-19.4,11.3-22.4
            c53.5-17.7,89.4-67.3,89.4-123.6c0-55.5-35.3-105-87.9-123.1c-9.3-3.2-14.2-13.3-11-22.6c3.2-9.3,13.3-14.2,22.6-11
            c66.9,23,111.8,86,111.8,156.7c0,71.6-45.7,134.9-113.7,157.4C396.4,414,394.5,414.3,392.7,414.3L392.7,414.3z"/>
<path id="small" style="fill:#009999;" d="M343.2,347.7c-4.4,0-8.5-2.8-10-7.2c-1.8-5.5,1.2-11.4,6.7-13.3
            c30.7-10.1,51.4-38.7,51.4-71.1c0-31.9-20.3-60.4-50.5-70.8c-5.5-1.9-8.4-7.9-6.5-13.3c1.9-5.5,7.9-8.4,13.3-6.5
            c38.7,13.3,64.7,49.7,64.7,90.6c0,41.4-26.4,78-65.8,91C345.4,347.5,344.2,347.7,343.2,347.7z"/>
<path id="speaker" style="fill:#009999;" d="M346.6,179.1V52.3c0-19.7-10.3-37.3-26.9-45.8C304.5-1.3,287.1,0,273,10.1L105.6,130
            H47.3C21.2,130,0,151.3,0,177.4v157.3C0,360.7,21.2,382,47.3,382h58.2L273,501.9c8,5.7,17.1,8.6,26.2,8.6c7,0,14-1.7,20.5-5.1
            c16.6-8.5,26.9-26.1,26.9-45.8V332.9L346.6,179.1z M93.6,346.5H47.3c-6.5,0-11.8-5.3-11.8-11.8V177.4c0-6.5,5.3-11.8,11.8-11.8h46.2
            V346.5z M311.1,459.6c0,7.6-3.9,12.3-7.6,14.2c-3.4,1.7-6.7,1.4-9.9-0.9L129.1,355.1V156.9L293.6,39c3.2-2.3,6.5-2.6,9.9-0.9
            c3.7,1.9,7.6,6.6,7.6,14.2L311.1,459.6L311.1,459.6z"/>
        </svg>
</template>

<script setup>
import {useAppStore} from "../../stores/app";
const appStore = useAppStore();
</script>