<template>
  <ScrollPanel class="siemensScrollBar" style="width: 100%;">
    <div v-for="block in store.selectedChannel.content">
      <Title v-if="block.type === 'title'" :block="block"></Title>
      <Video v-if="block.type === 'video'" :block="block"></Video>
      <Html v-if="block.type === 'html'" :block="block"></Html>
      <Image v-if="block.type === 'image'" :block="block"></Image>
    </div>
  </ScrollPanel>
</template>

<script setup>
import {useAppStore} from "../stores/app"
import Title from "./content/Title"
import Video from "./content/Video"
import Html from "./content/Html"
import Image from "./content/Image"
import ScrollPanel from 'primevue/scrollpanel'

const store = useAppStore()

function selectChannel(channel) {
  store.setSelectedChannel(channel)
}
</script>