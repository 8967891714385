import SoundBoard from '../components/SoundBoard';
import Locale from '../components/Locale';
import Share from "../components/Share";
import UserMix from "../components/UserMix";
import Legal from "../components/Legal";
import Welcome from "../components/Welcome";
import Index from "../components/Index";

const routes = [
    { path: '/', redirect: '/en' },
    {
        path: '/:locale(en|ja|zh|ko|fr|de)',
        component: Locale,
        children: [
            { path: '', component: Index },
            { path: 'welcome', component: Welcome },
            { path: 'share', component: Share},
            { path: 'soundboard', component: SoundBoard},
            { path: 'user-mix/:uid', component: UserMix},
            { path: 'legal/:page', component: Legal},
        ]
    },
]

export {routes}