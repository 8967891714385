<template>
  <main class="site-main site-main--mobile">
    <div class="site-main--controls">
      <button
          class="p-button"
          :class="{'highlighted-button':appStore.mobileShowInfo}"
          @click="appStore.mobileShowInfo = true"
          v-html="t('mobile.info')"
      >
      </button>
      <button
          class="p-button"
          :class="{'highlighted-button':!appStore.mobileShowInfo}"
          @click="appStore.mobileShowInfo = false"
          v-html="t('mobile.sound')"
      >
      </button>
    </div>
    <div v-show="appStore.mobileShowInfo" class="site-main--left">
      <div class="toolbar toolbar--left">
        <div class="nav--info">
          <slot name="content-navigation"></slot>
        </div>
      </div>
      <div class="content content--left">
        <slot name="content"></slot>
      </div>
    </div>
    <div v-show="!appStore.mobileShowInfo" class="site-main--right">
      <div class="toolbar toolbar--right">
        <slot name="controls"></slot>
      </div>
      <div class="content content--right">
        <slot name="channels"></slot>
      </div>
    </div>
  </main>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {useAppStore} from "../stores/app";

const appStore = useAppStore()

const {t, locale} = useI18n({useScope: 'global'})
</script>