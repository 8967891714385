<template>
  <div class="faderSelect--inner">
    <div class="faderSelect--value">
      <p class="text-center text-sm" v-html="selectedLabelAB"></p>
    </div>
    <SelectButton
        :unselectable="false"
        v-model="selectButtonValueAB"
        :options="selectButtonOptionsAB"
        optionLabel="letter" optionValue="id" dataKey="id"
    />
  </div>
  <div class="faderSelect--inner" v-if="channel.labelCd">
    <div class="faderSelect--value">
      <p class="text-center text-sm" v-html="selectedLabelCD"></p>
    </div>
    <SelectButton
        :unselectable="false"
        v-model="selectButtonValueCD"
        :options="selectButtonOptionsCD"
        optionLabel="letter" optionValue="id" dataKey="id"
    />
  </div>
</template>

<script setup>
import SelectButton from 'primevue/selectbutton';
import {computed, defineProps, onMounted, ref} from 'vue';
import {useAppStore} from "../stores/app";

const appStore = useAppStore();

const props = defineProps({
  channel: {required: true},
});

const channel = computed(() => props.channel)

const selectButtonValueAB = computed({
  get: () => {
    return channel.value.selectedA ? 'A' : 'B'
  },
  set: (value) => {
    appStore.setSelectedSound(channel.value.id, value)
  }
})

const selectButtonValueCD = computed({
  get: () => {
    return channel.value.selectedC ? 'C' : 'D'
  },
  set: (value) => {
    appStore.setSelectedSound(channel.value.id, value)
  }
})

const selectButtonOptionsAB = computed(() => {
  return [
      {id: 'A', letter: channel.value.letterA},
      {id: 'B', letter: channel.value.letterB},
  ]
})

const selectButtonOptionsCD = computed(() => {
  return [
    {id: 'C', letter: channel.value.letterC},
    {id: 'D', letter: channel.value.letterD},
  ]
})

const selectedLabelAB = computed(() => {
  if (channel.value.selectedA) return channel.value.labelA;
  if (channel.value.selectedB) return channel.value.labelB;
  return '';
})

const selectedLabelCD = computed(() => {
  if (channel.value.selectedC) return channel.value.labelC;
  if (channel.value.selectedD) return channel.value.labelD;
  return '';
})

</script>