export default {
    hello: 'こんにちは',
    loading: 'しばらくお待ちください、データを読み込んでいます...',
    toolbar: {
        share: '共有',
        moreInfo: '詳細を知りたいですか？',
        help: 'ヘルプ/チュートリアル',
    },
    share: {
        shareYourMix: 'ミックスを共有する',
        shareYourMixDescription: '献身的なファンとつながり、DJシーンでより確立されるための重要なプラットフォーム。',
        backToApp: 'アプリに戻る',
        submit: '送信',
        next: '次へ',
        yourName: 'あなたの名前',
        shareTitle: '%NAME%がミックスした%MIX%',
        needUrl: 'ミックスのURLが必要ですか？',
        copyUrl: 'URLをコピーする',
        urlCopied: 'コピーしました！'
    },
    userMix: {
        info: '<p>ユーザーミックスを閲覧しています。 <br>下のボタンをクリックしてミックスを読み込み、再生してください。</p>'
            + '<p>スライダーを調整して独自のミックスを作成してください。準備ができたら、ミックスを共有してください！</p>',
        loadMix: "準備ができたので、ミックスを読み込む"
    },
    yup: {
        fieldIsRequired: 'このフィールドは必須です'
    },
    tour: {
        previous: '前へ',
        next: '次へ',
        skip: 'ツアーをスキップ',
        finish: 'ツアーを終了',
    },
    controls: {
        title: 'マスターサウンドコントロール',
        resetAll: 'すべてリセット',
        play: '再生',
        compare: '比較する',
        autoUserMix: 'あなたの個人的なミックスに切り替える！'
    },
    content: {
        watchVideo: 'ビデオを見る'
    },
    footer: {
        termsOfUse: '利用規約',
        cookieStatement: 'Cookie ステートメント',
        privacyStatement: 'プライバシー ステートメント',
        dmca: 'DMCA'
    },
    mobile: {
        info: '情報',
        sound: 'サウンド'
    },
    welcome: {
        startTour: 'ツアーを開始する',
        skipTour: 'ツアーをスキップする'
    }
}
