export default {
    hello: '안녕하세요',
    loading: '잠시만 기다려 주세요, 데이터를 로딩 중입니다...',
    toolbar: {
        share: '공유하기',
        moreInfo: '자세히 알고 싶으신가요?',
        help: '도움말/튜토리얼',
    },
    share: {
        shareYourMix: '믹스 공유하기',
        shareYourMixDescription: '열심히 활동하는 팬들과 연결되고 DJ 씬에서 성장하기 위한 중요한 플랫폼입니다.',
        backToApp: '앱으로 돌아가기',
        submit: '제출하기',
        next: '다음',
        yourName: '이름',
        shareTitle: '%NAME% 님이 믹스한 %MIX%',
        needUrl: '믹스의 URL만 필요하신가요?',
        copyUrl: 'URL 복사하기',
        urlCopied: '복사 완료!'
    },
    userMix: {
        info: '<p>유저 믹스를 보고 있습니다. <br>아래 버튼을 클릭하여 믹스를 로드하고 재생하세요.</p>'
            + '<p>슬라이더를 조절하여 나만의 믹스를 만들어보세요. 준비가 되면 믹스를 공유해보세요!</p>',
        loadMix: '준비가 되었습니다. 믹스 로드하기'
    },
    yup: {
        fieldIsRequired: '이 필드는 필수 항목입니다'
    },
    tour: {
        previous: '이전',
        next: '다음',
        skip: '투어 건너뛰기',
        finish: '투어 완료',
    },
    controls: {
        title: '마스터 사운드 컨트롤',
        resetAll: '모두 초기화',
        play: '재생',
        compare: '비교하기',
        autoUserMix: '개인 믹스로 전환하기!'
    },
    content: {
        watchVideo: '비디오 보기'
    },
    footer: {
        termsOfUse: '이용 약관',
        cookieStatement: '쿠키 정책',
        privacyStatement: '개인 정보 처리 방침',
        dmca: 'DMCA'
    },
    mobile: {
        info: '정보',
        sound: '사운드'
    },
    welcome: {
        startTour: '여행 시작',
        skipTour: '여행 건너뛰기'
    }
}
